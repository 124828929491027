/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "As a full stack developer at Hectiq, you'll build high quality products based on recent advances in deep learning and data science."), "\n", React.createElement(_components.h3, null, "Your responsabilities"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bring to production the approches developed by Hectiq's scientific team"), "\n", React.createElement(_components.li, null, "Build internal tools to ease the workflow"), "\n", React.createElement(_components.li, null, "Design and implement high quality interfaces (UX) to interact with models and analysis"), "\n", React.createElement(_components.li, null, "Set up and maintain cloud infrastractures (AWS/Google Cloud)"), "\n"), "\n", React.createElement(_components.h3, null, "Typical profile"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Good knowledge of Python and React"), "\n", React.createElement(_components.li, null, "Serious about shipping high quality products and follow best practices"), "\n", React.createElement(_components.li, null, "Experience with development and deployment of backend softwares"), "\n", React.createElement(_components.li, null, "Knowledge of relational databases (SQL)"), "\n", React.createElement(_components.li, null, "Strong communication skills both oral and writing"), "\n", React.createElement(_components.li, null, "Bonus: Experience with iOS & Android developement"), "\n", React.createElement(_components.li, null, "Bonus: Familiar with AWS and Google Cloud"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
